// stores/modules/common.js
import { defineStore } from 'pinia'
import { ref } from 'vue'

// 初始化状态
const initState = () => ({
  loginBackUrl: '',
  logoUrl: '',
  curTabbarRoute: '/', //当前路由
  clickAd: false,
  upImages: [], // 上传的图片列表
  upVideos: [], // 上传的视频列表
  upCover: '', // 上传的封面
  upType: ['image', 'video', 'cover'], // 通用上传页面使用-显示上传的类型
  disableUpload: false, //是否禁用上传
})

export const useCommonStore = defineStore(
  'common',
  () => {
    const commonData = ref({ ...initState() })

    // 获取项目配置
    const getCommonData = (key) => {
      if (!(key in commonData.value)) {
        throw new Error(`属性 "${key}" 在 commonData 中不存在`)
      }
      // 返回对应的值
      return commonData.value[key]
    }
    // 设置项目配置
    const setCommonData = (key, val) => {
      commonData.value[key] = val
    }
    // 重置上传页面状态
    const resetUploadStatus = () => {
      commonData.value.upImages = []
      commonData.value.upVideos = []
      commonData.value.upCover = ''
      commonData.value.disableUpload = false
      commonData.value.upType = ['image', 'video', 'cover']
    }
    // 重置所有状态
    const resetState = () => {
      const state = initState()
    }

    return {
      commonData,
      resetState,
      getCommonData,
      setCommonData,
      resetUploadStatus,
    }
  },
  {
    persist: true, // 启用持久化
  },
)
