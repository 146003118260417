/**
 * 租房管理模块的
 */
import { defineStore } from 'pinia'
const initState = () => ({
  buildingForm: {}, // 新增/编辑楼栋表单
  houseBuildingId: null, // 当前编辑的楼盘id
  contractId: null, // 当前编辑的租房合同id
  houseRoomId: null, // 当前编辑的房间id
  contractForm: {}, // 租房合同表单
  adjustmentParams: {}, // 租金调整参数
  adjustBills: [], // 租金调整
  curExpense: {}, // 当前编辑的收费项目
  roomExpense: [], // 收费项目
  selectTime: null, // 当前选中周期高亮标记(用于基本信息表单中)
  step: 0, // 当前页面的步骤
  actionState: 0, // 操作状态，0：新增，1：编辑  2 ：查看
  meterRecord: [], // 抄表记录
  furnitureEdit: [], // 批量编辑家具
  billEditFee: [], // 创建合同时候添加费用用
  curEditExpense: {}, // 当前编辑的收费项目
  baseInfo: {}, // 账单标题 用于显示当前楼栋和房间和租客名字
})

export const useRentalStore = defineStore(
  'rental',
  () => {
    const rentalData = ref({ ...initState() })
    // 获取状态
    const getRentalData = (key) => {
      if (!(key in rentalData.value)) {
        throw new Error(`属性 "${key}" 在 rentalData.value 中不存在`)
      }
      return rentalData.value[key]
    }

    // 设置状态
    const setRentalData = (key, val) => {
      rentalData.value[key] = val
    }
    // 添加费用项目
    const pushRoomExpense = (val) => {
      rentalData.value.roomExpense.push(val)
    }

    // 删除费用项目
    const delRoomExpense = (index) => {
      rentalData.value.roomExpense.splice(index, 1)
    }
    // 重置租金调整
    const resetRentAdjust = () => {
      rentalData.value.adjustBills = []
    }
    const resetState = () => {
      rentalData.value = initState()
    }
    return {
      rentalData,
      resetState,
      setRentalData,
      getRentalData,
      pushRoomExpense,
      delRoomExpense,
      resetRentAdjust,
    }
  },
  {
    persist: true,
  },
)
