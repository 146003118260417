import { CustomRequestOptions } from '@/interceptors/request'
import { useAdminStore, useAuthStore } from '@/store'

// 重置登录态
const resetAuthInfo = () => {
  const authStore = useAuthStore()
  const adminStore = useAdminStore()
  authStore.clearAuthInfo()
  adminStore.clearAdminInfo()

  // TODO Check登录页
  const loginRoute = '/pages/auth/Login'
  // 注意，这里的url是 '/' 开头的，如 '/pages/index/index'，跟 'pages.json' 里面的 path 不同
  const url = location.href.split('#')[1]
  const redirectRoute = `${loginRoute}?redirect=${url}`
  uni.navigateTo({ url: redirectRoute })
}
// 使用数组管理请求任务
export const requestTaskQueue = ref<{ taskId: string; requestTask: UniApp.RequestTask }[]>([])

export const http = <T>(options: CustomRequestOptions & { taskId?: string }) => {
  return new Promise<IResData<T>>((resolve, reject) => {
    const { taskId } = options

    // 如果有 taskId，先取消之前的相同请求
    if (taskId) {
      const existingTaskIndex = requestTaskQueue.value.findIndex((task) => task.taskId === taskId)
      if (existingTaskIndex !== -1) {
        console.log(`取消之前的相同请求：${taskId}`)
        requestTaskQueue.value[existingTaskIndex].requestTask.abort() // 取消请求
        requestTaskQueue.value.splice(existingTaskIndex, 1) // 删除旧任务
      }
    }

    const requestTask = uni.request({
      ...options,
      dataType: 'json',
      success(res) {
        // 请求完成后，从队列中删除任务
        if (taskId) {
          const taskIndex = requestTaskQueue.value.findIndex((task) => task.taskId === taskId)
          if (taskIndex !== -1) {
            requestTaskQueue.value.splice(taskIndex, 1)
          }
        }

        if (res.statusCode === 401) {
          uni.showToast({
            icon: 'none',
            title: (res.data as IResData<T>).message || '网络请求错误，请稍后重试',
          })
          resetAuthInfo()
          reject(res)
        } else {
          resolve(res.data as IResData<T>)
        }
      },
      fail(err) {
        // 失败也要移除请求任务
        if (taskId) {
          const taskIndex = requestTaskQueue.value.findIndex((task) => task.taskId === taskId)
          if (taskIndex !== -1) {
            requestTaskQueue.value.splice(taskIndex, 1)
          }
        }

        uni.showToast({
          icon: 'none',
          title: '网络错误，换个网络试试',
        })
        reject(err)
      },
    })

    // 添加新的请求任务到队列
    if (taskId) {
      requestTaskQueue.value.push({ taskId, requestTask })
    }
  })
}

// **手动取消某个请求**
export const cancelRequest = (taskId: string) => {
  const taskIndex = requestTaskQueue.value.findIndex((task) => task.taskId === taskId)
  if (taskIndex !== -1) {
    requestTaskQueue.value[taskIndex].requestTask.abort()
    requestTaskQueue.value.splice(taskIndex, 1)
  }
}

// **取消所有请求**
export const cancelAllRequests = () => {
  requestTaskQueue.value.forEach((task) => task.requestTask.abort())
  requestTaskQueue.value = []
}

/**
 * GET 请求
 * @param url 后台地址
 * @param query 请求query参数
 * @returns
 */
export const httpGet = <T>(
  url: string,
  query?: Record<string, any>,
  data?: Record<string, any>,
) => {
  return http<T>({
    url,
    query,
    data,
    method: 'GET',
  })
}

/**
 * GET 请求
 * @param url 后台地址
 * @param header 请求头header参数
 * @param query 请求query参数
 * @returns
 */
// export const httpGetHeader = <T>(
//   url: string,
//   header?: Record<string, any>,
//   query?: Record<string, any>,
// ) => {
//   return http<T>({
//     url,
//     header,
//     query,
//     method: 'GET',
//   })
// }

/**
 * POST 请求
 * @param url 后台地址
 * @param data 请求body参数
 * @param query 请求query参数，post请求也支持query，很多微信接口都需要
 * @returns
 */
export const httpPost = <T>(
  url: string,
  data?: Record<string, any>,
  query?: Record<string, any>,
) => {
  return http<T>({
    url,
    query,
    data,
    method: 'POST',
  })
}

http.get = httpGet
http.post = httpPost
// http.getHeader = httpGetHeader
