import { http } from '@/utils/http'

// 获取资源标识
export const getResourceAPI = () => {
  return http.get(`/system/artwork/artworkGroups/`)
}

// 根据资源标识获取资源
export const findByArtworkGroupAPI = (query) => {
  return http.get(`/system/artwork/findByArtworkGroup/` + query)
}

// 获取字典
export const getDictsAPI = (query) => {
  return http.get(`/lib/getDicts`, query)
}
