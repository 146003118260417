import { http } from '@/utils/http'

// 微信登录
export const postLoginWxMinAPI = (code) => {
  return http.post('/member/user/auth/login', { code })
}

// 退出登录
export const postLogoutAPI = () => {
  return http.post('/member/user/auth/logout')
}

// 获取用户信息
export const getUserInfoAPI = () => {
  return http.get('/member/user/auth/info')
}

// 生产JSSDK配置信息
// export const getSDK = (data) => {
//   return http.post('/member/user/auth/build_jssdk', { data })
// }

export const getSDK = (data) => {
  return http({
    url: '/member/user/auth/build_jssdk',
    method: 'POST',
    data,
  })
}

// 会员用户权限列表
export const getRoleListAPI = () => {
  return http.get('/member/user/auth/get_role_list')
}

// 更新用户信息
export const putUserInfoAPI = (data) => {
  return http({
    url: '/member/user/auth/update_user_info',
    method: 'PUT',
    data,
  })
}

// 用户账单查询
export const getUserBillsAPI = (query) => {
  return http.get('/member/user/auth/bills', query)
}

// 用户积分信息查询
export const getUserIntegralAPI = () => {
  return http.get('/member/user/auth/my_integral')
}

// 我的订单角标数据
export const getCornerDataAPI = () => {
  return http.get('/member/user/order/corner_data')
}
//更新用户支付付款码图片（微信，支付宝）
export const updatePayeeCodeAPI = (data) => {
  return http({
    url: '/member/user/auth/update_payee_code',
    method: 'PUT',
    data,
  })
}

// 会员用户支付码图片信息
export const getUserPayeeCodeAPI = () => {
  return http.get('/member/user/auth/get_user_payee_code')
}
