<!-- web环境使用Teleport挂载到原生tabbar上 非web环境使用自定义tabbar -->
<template>
  <wd-config-provider :theme-vars="themeVars" custom-style="min-height: 100%">
    <!-- <wd-navbar
      :title="activeTabbar.title"
      safe-area-inset-top
      placeholder
      fixed
      :bordered="false"
    /> -->
    <slot />
    <!-- 自定义tabbr -->
    <!-- Web 环境下渲染 -->

    <template v-if="el && !hasTabbar">
      <Teleport defer to="uni-tabbar">
        <view id="wat-tabbar">
          <WatTabbar :route="route" />
        </view>
      </Teleport>
    </template>
    <template v-else>
      <view id="wat-tabbar">
        <WatTabbar :route="route" />
      </view>
    </template>






    <wd-notify />
    <wd-toast />
    <wd-message-box />
    <!-- <privacy-popup /> -->
  </wd-config-provider>
</template>

<script lang="ts" setup>
import type { ConfigProviderThemeVars } from 'wot-design-uni'
import WatTabbar from '@/components/wat-tabbar/WatTabbar.vue'
import { useCommonStore } from '@/store'
const commonStore = useCommonStore()
const themeVars = reactive<ConfigProviderThemeVars>({
  colorTheme: '#ff6600',
  buttonPrimaryBgColor: '#ff6600',
  swiperRadius: '0',
  cellWrapperPadding: '1rem',
})
//注意 使用pinia可以 watch不行
const route = computed(() => commonStore.getCommonData('curTabbarRoute'))

const el = ref(false)
const hasTabbar = document.querySelector('#wat-tabbar')

onShow(() => {



  const pages = getCurrentPages()
  const currentPage = pages[pages.length - 1]
  commonStore.setCommonData('curTabbarRoute', currentPage.route)


  // 判断是否有挂载tabbar的目标元素
  const targetElement = document.querySelector('.uni-tabbar-bottom')
  el.value = targetElement !== null

  // 移除原生tabbar
  const element = document.querySelector('.uni-tabbar')
  if (element) {
    element.remove() // 移除目标元素
  }

})
</script>
<script lang="ts">
export default {
  options: {
    addGlobalClass: true,
    virtualHost: true,
    styleIsolation: 'shared',
  },
}
</script>
