import { findByArtworkGroupAPI } from '@/service/resource/resource'
import { useCommonStore } from '@/store/modules/common'
import { useToast } from 'wot-design-uni'

// 封装 logo 获取逻辑
export function useLogo() {
  const commonStore = useCommonStore()
  // 创建一个全局的 logo 状态
  const logoImgSrc = computed(() => commonStore.getCommonData('logoUrl'))
  const toast = useToast()

  const getLogoImg = async () => {
    try {
      const commonMark = 'common'
      const logo = 'logo'
      const res = await findByArtworkGroupAPI(commonMark)

      if (res.code !== 200) {
        console.error(res.message)
        // toast.error('获取 Logo 失败')
        return
      }

      const logoMark = res.data.artwork.find((item) => item.code === logo)
      if (logoMark) {
        commonStore.setCommonData('logoUrl', logoMark?.file_full_url)
      }
    } catch (error) {
      console.error('获取 Logo 出错:', error)
      // toast.error('Logo 加载失败')
    }
  }

  return { logoImgSrc, getLogoImg }
}
